import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux'
import { makeStyles } from '@material-ui/styles';

import withErrorHandler from '../../../hocs/withErrorHandler'
import axios from '../../../httpClients/axios-datamerch'
import * as actions from '../../../store/actions'

import { AccountsTable } from '../../../components/Account';
import { CircularProgress, Backdrop, Card, CardHeader, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: '100'
  }
}));

const AccountList = props => {
  const classes = useStyles();
  
  const { 
    onFetchAccounts,
    onAccountGrantApiAccess,
    onAccountRevokeApiAccess,
    onAccountMfaRequiredToggle,
    onAccountArchive,
    onExportContacts,
    onResetContacts,
    accounts,
    token,
    loading,
    pagination,
    contacts
  } = props

  const [page, setPage] = useState(0)
  const [per_page, setPerPage] = useState(25)
  const [search, setSearch] = useState('')
  const [membership_filter, setMembershipFilter] = useState('')
  const [plan_level_filter, setPlanLevelFilter] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  
  useEffect(() => {
    if (search) {
      setIsFiltered(true)
    }
    onFetchAccounts(page, per_page, search, membership_filter, plan_level_filter);
  }, [onFetchAccounts, page, per_page, search, membership_filter, plan_level_filter])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (contacts.length) {
      downloadJSONAsCSV()
      onResetContacts()
    }
  }, [contacts])

  function downloadJSONAsCSV() {
    let csvData = jsonToCsv(contacts); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    const dt = new Date().toISOString().split('.')[0].replace(/[^\d]/gi,'');
    a.download = 'datamerch_contacts_'+ dt + '.csv';
    document.body.appendChild(a);
    a.click();
  }
  
  function jsonToCsv(jsonData) {
    let csv = '';
    // Get the headers
    // let headers = Object.keys(jsonData[0]);
    let headers = ['funder', 'first_name', 'last_name', 'email', 'membership_level'];
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row) {
        let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
        csv += data + '\n';
    });
    return csv;
  }

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value)
  }

  const handleToggleApiAccessClick = (event, account) => {
    event.preventDefault();
    if (account.can_access_api) {
      onAccountRevokeApiAccess(token, account.resource_id)
    } else {
      onAccountGrantApiAccess(token, account.resource_id)
    }
  }

  const handleToggleMfaRequiredClick = (event, account) => {
    event.preventDefault();
    onAccountMfaRequiredToggle(account.resource_id)
  }

  const handleArchiveClick = (event, accountId) => {
    event.preventDefault();
    onAccountArchive(token, accountId)
  }

  const handleExportContactsClick = (event) => {
    event.preventDefault();
    onExportContacts(token)
  }

  const handleSearchClick = (event, search) => {
    setIsFiltered(true)
    setSearch(search)
  }

  const handleSearchCancelClick = (event) => {
    setIsFiltered(false)
    setSearch('')
  }

  const handleMembershipFilterChange = (event, membership_filter) => {
    setIsFiltered(true)
    setMembershipFilter(membership_filter)
  }

  const handlePlanLevelFilterChange = (event, plan_level_filter) => {
    setIsFiltered(true)
    setPlanLevelFilter(plan_level_filter)
  }

  let accountsTable = (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
  if(!loading){
    accountsTable = (
      <AccountsTable
        accounts={accounts}
        onAccountApiAccessToggle={(event, account) => handleToggleApiAccessClick(event, account)}
        onAccountMfaRequiredToggle={(event, account) => handleToggleMfaRequiredClick(event, account)}
        onArchiveClick={(event, accountId) => handleArchiveClick(event, accountId)}
        pagination={pagination}
        onPageChange={(event, newPage) => handlePageChange(event, newPage)}
        onPerPageChange={handlePerPageChange}
        onSearchClick={(event, search) => handleSearchClick(event, search)}
        onSearchCancelClick={handleSearchCancelClick}
        onMembershipFilterChange={(event, membership_filter) => handleMembershipFilterChange(event, membership_filter)}
        onPlanLevelFilterChange={(event, plan_level_filter) => handlePlanLevelFilterChange(event, plan_level_filter)}
        onExportContactsClick={(event) => handleExportContactsClick(event)}
        currentSearch={search}
        currentMembershipFilter={membership_filter}
        currentPlanLevelFilter={plan_level_filter}
        isFiltered={isFiltered}
      />
    )
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Card>
          <CardHeader
            title="Funders"
          />
          <Divider />
          {accountsTable}
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accounts: state.account.accounts,
    loading: state.account.loading,
    token: state.auth.token,
    pagination: state.account.pagination,
    contacts: state.account.contacts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAccounts: (page, per_page, search, membership_filter, plan_level_filter) => dispatch(actions.fetchAccounts(page, per_page, search, membership_filter, plan_level_filter)),
    onAccountArchive: (token, accountId) => dispatch(actions.archiveAccount(token, accountId)),
    onAccountGrantApiAccess: (token, accountId) => dispatch(actions.grantApiAccessAccount(token, accountId)),
    onAccountRevokeApiAccess: (token, accountId) => dispatch(actions.revokeApiAccessAccount(token, accountId)),
    onExportContacts: (token) => dispatch(actions.exportContacts(token)),
    onResetContacts: () => dispatch(actions.resetContacts()),
    onAccountMfaRequiredToggle: (id) => dispatch(actions.mfaRequiredToggle(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(AccountList, axios))