import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../../helpers/utilities'

const initialState = {
  accounts: [],
  account: null,
  id: null,
  saving: false,
  error: null,
  flash: null,
  payment_method_update_url: null,
  payment_method_update_dialog_open: false,
  payment_method: null,
  pagination: {
    current_page: 0,
    per_page: 25,
    total_pages: 1,
    total_count: 0
  },
  contacts: []
}

const fetchAccountsStart = (state, action) => {
  return updateObject(state, {loading: true, accounts: [], account: null})
}

const fetchAccountsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    accounts: action.accounts,
    pagination: action.pagination
  })
}

const fetchAccountsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchDatamerchMembersStart = (state, action) => {
  return updateObject(state, {loading: true, accounts: [], account: null})
}

const fetchDatamerchMembersSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    accounts: action.accounts,
    pagination: action.pagination
  })
}

const fetchDatamerchMembersFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const fetchAccountStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchAccountSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    account: action.account,
    id: null
  })
}

const fetchAccountFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const addAccountStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const addAccountSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      id: action.id
  } );
};

const addAccountFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const updateAccountStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const updateAccountSuccess = ( state, action ) => {
  return updateObject( state, {
      loading: false,
      saving: false,
      // id: action.id
  } );
};

const updateAccountFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const grantApiAccessAccountStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const grantApiAccessAccountSuccess = ( state, action ) => {
  const updatedAccounts = state.accounts.map(account => {
    if (account.resource_id === action.id) {
      return {
        ...account,
        api_granted: action.accountData.api_granted,
        api_revoked: action.accountData.api_revoked,
        can_access_api: action.accountData.can_access_api
      }
    } else {
      return account
    }
  })
  
  return updateObject( state, {
      loading: false,
      saving: false,
      accounts: updatedAccounts
  } );
};

const grantApiAccessAccountFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const revokeApiAccessAccountStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const revokeApiAccessAccountSuccess = ( state, action ) => {
  const updatedAccounts = state.accounts.map(account => {
    if (account.resource_id === action.id) {
      return {
        ...account,
        api_granted: action.accountData.api_granted,
        api_revoked: action.accountData.api_revoked,
        can_access_api: action.accountData.can_access_api
      }
    } else {
      return account
    }
  })

  return updateObject( state, {
      loading: false,
      saving: false,
      accounts: updatedAccounts
  } );
};

const revokeApiAccessAccountFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const archiveAccountStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const archiveAccountSuccess = ( state, action ) => {
  const updatedAccounts = state.accounts.filter(account => account.resource_id !== action.id)
  
  return updateObject( state, {
      loading: false,
      saving: false,
      accounts: updatedAccounts
  } );
};

const archiveAccountFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const startTrialStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const startTrialSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const startTrialFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const cancelTrialStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const cancelTrialSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const membershipUpgradeFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const membershipUpgradeStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const membershipUpgradeSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const membershipDowngradeFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const membershipDowngradeStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const membershipDowngradeSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const cancelTrialFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const contributionOverrideToggleStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const contributionOverrideToggleSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const contributionOverrideToggleFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const contributionRecalculateStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const contributionRecalculateSuccess = ( state, action ) => {
  return updateObject( state, {
    loading: false,
    saving: false,
    id: action.id,
    account: action.accountData
  } );
};

const contributionRecalculateFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const mfaRequiredToggleStart = (state, action) => {
  return updateObject(state, {saving: true})
}

const mfaRequiredToggleSuccess = ( state, action ) => {
  const updatedAccounts = state.accounts.map(account => {
    if (account.resource_id === action.id) {
      return {
        ...account,
        mfa_required: action.accountData.mfa_required
      }
    } else {
      return account
    }
  })

  return updateObject( state, {
      loading: false,
      saving: false,
      accounts: updatedAccounts,
      account: action.accountData
  } );
};

const mfaRequiredToggleFail = (state, action) => {
  return updateObject(state, {saving: false})
}

const fetchPaymentMethodUpdateUrlStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchPaymentMethodUpdateUrlSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    payment_method_update_url: action.id,
    id: null
  })
}

const fetchPaymentMethodUpdateUrlFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const openPaymentMethodUpdateDialog = (state, action) => {
  return updateObject(state, {
    payment_method_update_dialog_open: true
  })
}

const closePaymentMethodUpdateDialog = (state, action) => {
  return updateObject(state, {
    payment_method_update_dialog_open: false
  })
}

const fetchPaymentMethodStart = (state, action) => {
  return updateObject(state, {loading: true})
}

const fetchPaymentMethodSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    payment_method: action.id,
    id: null
  })
}

const fetchPaymentMethodFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    payment_method: null
  })
}


const exportContactsStart = (state, action) => {
  return updateObject(state, {loading: true, contacts: []})
}

const exportContactsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    contacts: action.contacts
  })
}

const exportContactsFail = (state, action) => {
  return updateObject(state, {loading: false})
}

const resetContacts = (state) => {
  return updateObject(state, {
    loading: false,
    contacts: []
  })
}



const reducer = (state = initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_ACCOUNTS_START:
      return fetchAccountsStart(state, action)
    case actionTypes.FETCH_ACCOUNTS_SUCCESS:
      return fetchAccountsSuccess(state, action)
    case actionTypes.FETCH_ACCOUNTS_FAIL:
      return fetchAccountsFail(state, action)
    case actionTypes.FETCH_DATAMERCH_MEMBERS_START:
      return fetchAccountsStart(state, action)
    case actionTypes.FETCH_DATAMERCH_MEMBERS_SUCCESS:
      return fetchAccountsSuccess(state, action)
    case actionTypes.FETCH_DATAMERCH_MEMBERS_FAIL:
      return fetchAccountsFail(state, action)
    case actionTypes.FETCH_ACCOUNT_START:
      return fetchAccountStart(state, action)
    case actionTypes.FETCH_ACCOUNT_SUCCESS:
      return fetchAccountSuccess(state, action)
    case actionTypes.FETCH_ACCOUNT_FAIL:
      return fetchAccountFail(state, action)
    case actionTypes.UPDATE_ACCOUNT_START:
      return updateAccountStart(state, action)
    case actionTypes.UPDATE_ACCOUNT_SUCCESS:
      return updateAccountSuccess(state, action)
    case actionTypes.UPDATE_ACCOUNT_FAIL:
      return updateAccountFail(state, action)
    case actionTypes.ADD_ACCOUNT_START:
      return addAccountStart(state, action)
    case actionTypes.ADD_ACCOUNT_SUCCESS:
      return addAccountSuccess(state, action)
    case actionTypes.ADD_ACCOUNT_FAIL:
      return addAccountFail(state, action)
    case actionTypes.GRANT_API_ACCESS_ACCOUNT_START:
      return grantApiAccessAccountStart(state, action)
    case actionTypes.GRANT_API_ACCESS_ACCOUNT_SUCCESS:
      return grantApiAccessAccountSuccess(state, action)
    case actionTypes.GRANT_API_ACCESS_ACCOUNT_FAIL:
      return grantApiAccessAccountFail(state, action)
    case actionTypes.REVOKE_API_ACCESS_ACCOUNT_START:
      return revokeApiAccessAccountStart(state, action)
    case actionTypes.REVOKE_API_ACCESS_ACCOUNT_SUCCESS:
      return revokeApiAccessAccountSuccess(state, action)
    case actionTypes.REVOKE_API_ACCESS_ACCOUNT_FAIL:
      return revokeApiAccessAccountFail(state, action)
    case actionTypes.ARCHIVE_ACCOUNT_START:
      return archiveAccountStart(state, action)
    case actionTypes.ARCHIVE_ACCOUNT_SUCCESS:
      return archiveAccountSuccess(state, action)
    case actionTypes.ARCHIVE_ACCOUNT_FAIL:
      return archiveAccountFail(state, action)
    case actionTypes.START_TRIAL_START:
      return startTrialStart(state, action)
    case actionTypes.START_TRIAL_SUCCESS:
      return startTrialSuccess(state, action)
    case actionTypes.START_TRIAL_FAIL:
      return startTrialFail(state, action)
    case actionTypes.CANCEL_TRIAL_START:
      return cancelTrialStart(state, action)
    case actionTypes.CANCEL_TRIAL_SUCCESS:
      return cancelTrialSuccess(state, action)
    case actionTypes.CANCEL_TRIAL_FAIL:
      return cancelTrialFail(state, action)
    case actionTypes.MEMBERSHIP_UPGRADE_START:
      return membershipUpgradeStart(state, action)
    case actionTypes.MEMBERSHIP_UPGRADE_SUCCESS:
      return membershipUpgradeSuccess(state, action)
    case actionTypes.MEMBERSHIP_UPGRADE_FAIL:
      return membershipUpgradeFail(state, action)
    case actionTypes.MEMBERSHIP_DOWNGRADE_START:
      return membershipDowngradeStart(state, action)
    case actionTypes.MEMBERSHIP_DOWNGRADE_SUCCESS:
      return membershipDowngradeSuccess(state, action)
    case actionTypes.MEMBERSHIP_DOWNGRADE_FAIL:
      return membershipDowngradeFail(state, action)
    case actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_START:
      return contributionOverrideToggleStart(state, action)
    case actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_SUCCESS:
      return contributionOverrideToggleSuccess(state, action)
    case actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_FAIL:
      return contributionOverrideToggleFail(state, action)
    case actionTypes.CONTRIBUTION_RECALCULATE_START:
      return contributionRecalculateStart(state, action)
    case actionTypes.CONTRIBUTION_RECALCULATE_SUCCESS:
      return contributionRecalculateSuccess(state, action)
    case actionTypes.CONTRIBUTION_RECALCULATE_FAIL:
      return contributionRecalculateFail(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_START:
      return mfaRequiredToggleStart(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_SUCCESS:
      return mfaRequiredToggleSuccess(state, action)
    case actionTypes.MFA_REQUIRED_TOGGLE_FAIL:
      return mfaRequiredToggleFail(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_START:
      return fetchPaymentMethodUpdateUrlStart(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_SUCCESS:
      return fetchPaymentMethodUpdateUrlSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_FAIL:
      return fetchPaymentMethodUpdateUrlFail(state, action)
    case actionTypes.OPEN_PAYMENT_METHOD_UPDATE_DIALOG:
      return openPaymentMethodUpdateDialog(state, action)
    case actionTypes.CLOSE_PAYMENT_METHOD_UPDATE_DIALOG:
      return closePaymentMethodUpdateDialog(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_START:
      return fetchPaymentMethodStart(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_SUCCESS:
      return fetchPaymentMethodSuccess(state, action)
    case actionTypes.FETCH_PAYMENT_METHOD_FAIL:
      return fetchPaymentMethodFail(state, action)  
    case actionTypes.EXPORT_CONTACTS_START:
      return exportContactsStart(state, action)
    case actionTypes.EXPORT_CONTACTS_SUCCESS:
      return exportContactsSuccess(state, action)
    case actionTypes.EXPORT_CONTACTS_FAIL:
      return exportContactsFail(state, action)
    case actionTypes.RESET_CONTACTS:
      return resetContacts(state, action)
    default:
      return state
  }
}
    
export default reducer
