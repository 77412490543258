import * as actionTypes from './actionTypes'
import axios from '../../httpClients/axios-datamerch'
import {authHeaders} from '../../helpers'
import {addToast} from './toasts'

export const fetchAccountsSuccess = (accounts, pagination) => {
  return {
    type: actionTypes.FETCH_ACCOUNTS_SUCCESS,
    accounts: accounts,
    pagination: pagination
  }
}

export const fetchAccountsFail = (error) => {
  return {
    type: actionTypes.FETCH_ACCOUNTS_FAIL,
    error: error
  }
}

export const fetchAccountsStart = () => {
  return {
    type: actionTypes.FETCH_ACCOUNTS_START
  }
}

export const fetchAccounts = (page, per_page, search, membership_filter, plan_level_filter) => {
  return dispatch => {
    dispatch(fetchAccountsStart())
    let url = `/accounts?page=${page + 1}&per_page=${per_page}&search=${search}&membership_filter=${membership_filter}&plan_level_filter=${plan_level_filter}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedAccounts = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedAccounts.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchAccountsSuccess(fetchedAccounts, pagination))
      } else {
        dispatch(fetchAccountsFail('An error occurred while attempting to retrieve funders.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve funders.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchAccountsFail(error.response.data.errors))
    })
  }
}


export const fetchDatamerchMembersSuccess = (accounts, pagination) => {
  return {
    type: actionTypes.FETCH_DATAMERCH_MEMBERS_SUCCESS,
    accounts: accounts,
    pagination: pagination
  }
}

export const fetchDatamerchMembersFail = (error) => {
  return {
    type: actionTypes.FETCH_DATAMERCH_MEMBERS_FAIL,
    error: error
  }
}

export const fetchDatamerchMembersStart = () => {
  return {
    type: actionTypes.FETCH_DATAMERCH_MEMBERS_START
  }
}

export const fetchDatamerchMembers = (token, page, per_page, search) => {
  return dispatch => {
    dispatch(fetchDatamerchMembersStart())
    let url = `/datamerch_members`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedAccounts = []
        const pagination = response.data.pagination
        for(let key in response.data.data){
          fetchedAccounts.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(fetchDatamerchMembersSuccess(fetchedAccounts, pagination))
      } else {
        dispatch(fetchDatamerchMembersFail('An error occurred while attempting to retrieve members.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve members.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchDatamerchMembersFail(error.response.data.errors))
    })
  }
}

export const fetchAccountSuccess = (account) => {
  return {
    type: actionTypes.FETCH_ACCOUNT_SUCCESS,
    account: account
  }
}

export const fetchAccountFail = (error) => {
  return {
    type: actionTypes.FETCH_ACCOUNT_FAIL,
    error: error
  }
}

export const fetchAccountStart = () => {
  return {
    type: actionTypes.FETCH_ACCOUNT_START
  }
}

export const fetchAccount = (token, id) => {
  return dispatch => {
    dispatch(fetchAccountStart())
    let url = `/accounts/${id}`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const fetchedAccount = {...response.data.data}
        dispatch(fetchAccountSuccess(fetchedAccount))
      } else {
        dispatch(fetchAccountFail('An error occurred while attempting to retrieve funder.'))
        // dispatch(addToast({
        //   message: 'An error occurred while attempting to retrieve funder.',
        //   severity: 'error',
        //   duration: null
        // }))
      }
    })
    .catch(error => {
      dispatch(fetchAccountFail(error.response.data.errors))
    })
  }
}

export const addAccountStart = () => {
  return {
    type: actionTypes.ADD_ACCOUNT_START
  }
}

export const addAccountSuccess = (id, accountData) => {
  return {
    type: actionTypes.ADD_ACCOUNT_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const addAccountFail = (error) => {
  return {
    type: actionTypes.ADD_ACCOUNT_FAIL,
    error: error
  }
}

export const addAccount = (accountData, token) => {
  const modifiedAccountData = {
    account: {
      name: accountData.account.name,
      website: accountData.account.website,
      primary_contact_email: accountData.account.primaryContactEmail
    }
  }
  
  return dispatch => {
    dispatch(addAccountStart())
    let url = '/accounts'
    let config = authHeaders()
    axios.post(url, modifiedAccountData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(addAccountSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Funder has been created.',
          severity: 'success'
        }))
      } else {
        dispatch(addAccountFail('An error occurred while attempting to create funder.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to create funder.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(addAccountFail(error.response.data.errors))
    })
  }
}

export const updateAccountStart = () => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_START
  }
}

export const updateAccountSuccess = (id, accountData) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const updateAccountFail = (error) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_FAIL,
    error: error
  }
}

export const updateAccount = (token, accountData, id) => {
  const modifiedAccountData = {
    account: {
      name: accountData.account.name,
      website: accountData.account.website,
      primary_contact_email: accountData.account.primaryContactEmail,
      merchant_subscription_enabled: accountData.account.merchant_subscription_enabled,
      merchant_subscription_notification_email: accountData.account.merchant_subscription_notification_email,
      merchant_subscription_notification_webhook: accountData.account.merchant_subscription_notification_webhook
    }
  }
  
  return dispatch => {
    dispatch(updateAccountStart())
    let url = `/accounts/${id}`
    let config = authHeaders()
    axios.put(url, modifiedAccountData, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(updateAccountSuccess(data.resource_id, data))
        dispatch(updateAuthAcount(data))
        dispatch(addToast({
          message: 'Updated successfully.',
          severity: 'success'
        }))
      } else {
        dispatch(updateAccountFail('An error occurred while attempting to update.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(updateAccountFail(error.response.data.errors))
    })
  }
}

export const grantApiAccessAccountStart = () => {
  return {
    type: actionTypes.GRANT_API_ACCESS_ACCOUNT_START
  }
}

export const grantApiAccessAccountSuccess = (id, accountData) => {
  return {
    type: actionTypes.GRANT_API_ACCESS_ACCOUNT_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const grantApiAccessAccountFail = (error) => {
  return {
    type: actionTypes.GRANT_API_ACCESS_ACCOUNT_FAIL,
    error: error
  }
}

export const grantApiAccessAccount = (token, id) => {
  return dispatch => {
    dispatch(grantApiAccessAccountStart())
    let url = `accounts/${id}/grant_api_access`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(grantApiAccessAccountSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'API access has been granted.',
          severity: 'success'
        }))
      } else {
        dispatch(grantApiAccessAccountFail('An error occurred while attempting to grant API access.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to grant API access.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(grantApiAccessAccountFail(error.response.data.errors))
    })
  }
}

export const revokeApiAccessAccountStart = () => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_ACCOUNT_START
  }
}

export const revokeApiAccessAccountSuccess = (id, accountData) => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_ACCOUNT_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const revokeApiAccessAccountFail = (error) => {
  return {
    type: actionTypes.REVOKE_API_ACCESS_ACCOUNT_FAIL,
    error: error
  }
}

export const revokeApiAccessAccount = (token, id) => {
  return dispatch => {
    dispatch(revokeApiAccessAccountStart())
    let url = `accounts/${id}/revoke_api_access`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(revokeApiAccessAccountSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'API access has been revoked.',
          severity: 'success'
        }))
      } else {
        dispatch(revokeApiAccessAccountFail('An error occurred while attempting to revoke API access.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to revoke API access.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(revokeApiAccessAccountFail(error.response.data.errors))
    })
  }
}

export const archiveAccountStart = () => {
  return {
    type: actionTypes.ARCHIVE_ACCOUNT_START
  }
}

export const archiveAccountSuccess = (id, accountData) => {
  return {
    type: actionTypes.ARCHIVE_ACCOUNT_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const archiveAccountFail = (error) => {
  return {
    type: actionTypes.ARCHIVE_ACCOUNT_FAIL,
    error: error
  }
}

export const archiveAccount = (token, id) => {
  return dispatch => {
    dispatch(archiveAccountStart())
    let url = `accounts/${id}/archive`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(archiveAccountSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Funder has been archived.',
          severity: 'success'
        }))
      } else {
        dispatch(archiveAccountFail('An error occurred while attempting to archive funder.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to archive funder.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(archiveAccountFail(error.response.data.errors))
    })
  }
}

export const startTrialStart = () => {
  return {
    type: actionTypes.START_TRIAL_START
  }
}

export const startTrialSuccess = (id, accountData) => {
  return {
    type: actionTypes.START_TRIAL_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const startTrialFail = (error) => {
  return {
    type: actionTypes.START_TRIAL_FAIL,
    error: error
  }
}

export const trialStart = (id) => {
  return dispatch => {
    dispatch(startTrialStart())
    let url = `accounts/${id}/start_trial`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(startTrialSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Trial has been started.',
          severity: 'success'
        }))
      } else {
        dispatch(startTrialFail('An error occurred while attempting to start trial.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to start trial.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(startTrialFail(error.response.data.errors))
    })
  }
}

export const cancelTrialStart = () => {
  return {
    type: actionTypes.CANCEL_TRIAL_START
  }
}

export const cancelTrialSuccess = (id, accountData) => {
  return {
    type: actionTypes.CANCEL_TRIAL_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const cancelTrialFail = (error) => {
  return {
    type: actionTypes.CANCEL_TRIAL_FAIL,
    error: error
  }
}

export const trialCancel = (id) => {
  return dispatch => {
    dispatch(cancelTrialStart())
    let url = `accounts/${id}/cancel_trial`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(cancelTrialSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Trial has been cancelled.',
          severity: 'success'
        }))
      } else {
        dispatch(cancelTrialFail('An error occurred while attempting to cancel trial.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to cancel trial.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(cancelTrialFail(error.response.data.errors))
    })
  }
}

export const updateAuthAcount = (accountData) => {
  console.log("updateAuthAccount", accountData)
  return {
    type: actionTypes.AUTH_UPDATE_ACCOUNT,
    accountData: accountData
  }
}

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const membershipUpgradeStart = () => {
  return {
    type: actionTypes.MEMBERSHIP_UPGRADE_START
  }
}

export const membershipUpgradeSuccess = (id, accountData) => {
  return {
    type: actionTypes.MEMBERSHIP_UPGRADE_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const membershipUpgradeFail = (error) => {
  return {
    type: actionTypes.MEMBERSHIP_UPGRADE_FAIL,
    error: error
  }
}

export const membershipUpgrade = (id, account_view) => {
  return dispatch => {
    dispatch(membershipUpgradeStart())
    let url = `accounts/${id}/subscription/upgrade`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(membershipUpgradeSuccess(data.resource_id, data, account_view))
        if (account_view) {
          dispatch(updateAuthAcount(data))
          // dispatch({ type: 'LOGOUT_USER' })
        }
        dispatch(addToast({
          message: 'Membership has been upgraded. Prorated charges will be included on your next invoice.',
          severity: 'success',
          duration: 10000
        }))
      } else {
        dispatch(membershipUpgradeFail('An error occurred while attempting to upgrade membership.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to upgrade membership.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(membershipUpgradeFail(error.response.data.errors))
    })
  }
}

export const membershipDowngradeStart = () => {
  return {
    type: actionTypes.MEMBERSHIP_DOWNGRADE_START
  }
}

export const membershipDowngradeSuccess = (id, accountData, account_view) => {
  return {
    type: actionTypes.MEMBERSHIP_DOWNGRADE_SUCCESS,
    id: id,
    accountData: accountData,
    account_view: account_view
  }
}

export const membershipDowngradeFail = (error) => {
  return {
    type: actionTypes.MEMBERSHIP_DOWNGRADE_FAIL,
    error: error
  }
}

export const membershipDowngrade = (id, account_view) => {
  return dispatch => {
    dispatch(membershipDowngradeStart())
    let url = `accounts/${id}/subscription/downgrade`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(membershipDowngradeSuccess(data.resource_id, data))
        if (account_view) {
          dispatch(updateAuthAcount(data))
          // dispatch({ type: 'LOGOUT_USER' })
        }
        dispatch(addToast({
          message: 'Membership has been downgraded.  Prorated credit will be included on your next invoice.',
          severity: 'success',
          duration: 10000
        }))
      } else {
        dispatch(membershipDowngradeFail('An error occurred while attempting to downgrade membership.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to downgrade membership.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(membershipDowngradeFail(error.response.data.errors))
    })
  }
}


export const contributionOverrideToggleStart = () => {
  return {
    type: actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_START
  }
}

export const contributionOverrideToggleSuccess = (id, accountData) => {
  return {
    type: actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const contributionOverrideToggleFail = (error) => {
  return {
    type: actionTypes.CONTRIBUTION_OVERRIDE_TOGGLE_FAIL,
    error: error
  }
}

export const contributionOverrideToggle = (id) => {
  return dispatch => {
    dispatch(contributionOverrideToggleStart())
    let url = `accounts/${id}/contribution_override_toggle`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(contributionOverrideToggleSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Contribution Override has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(contributionOverrideToggleFail('An error occurred while attempting to update contribution override.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update contribution override.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(contributionOverrideToggleFail(error.response.data.errors))
    })
  }
}

export const contributionRecalculateStart = () => {
  return {
    type: actionTypes.CONTRIBUTION_RECALCULATE_START
  }
}

export const contributionRecalculateSuccess = (id, accountData) => {
  return {
    type: actionTypes.CONTRIBUTION_RECALCULATE_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const contributionRecalculateFail = (error) => {
  return {
    type: actionTypes.CONTRIBUTION_RECALCULATE_FAIL,
    error: error
  }
}

export const contributionRecalculate = (id) => {
  return dispatch => {
    dispatch(contributionRecalculateStart())
    let url = `accounts/${id}/contribution_recalculate`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(contributionRecalculateSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'Contribution has been recalculated.',
          severity: 'success'
        }))
      } else {
        dispatch(contributionRecalculateFail('An error occurred while attempting to recalculate contribution.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to recalculate contribution.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(contributionRecalculateFail(error.response.data.errors))
    })
  }
}

export const mfaRequiredToggleStart = () => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_START
  }
}

export const mfaRequiredToggleSuccess = (id, accountData) => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_SUCCESS,
    id: id,
    accountData: accountData
  }
}

export const mfaRequiredToggleFail = (error) => {
  return {
    type: actionTypes.MFA_REQUIRED_TOGGLE_FAIL,
    error: error
  }
}

export const mfaRequiredToggle = (id) => {
  return dispatch => {
    dispatch(mfaRequiredToggleStart())
    let url = `accounts/${id}/mfa_required_toggle`
    let config = authHeaders()
    axios.put(url, {}, config)
    .then(response => {
      if (response){
        const data = response.data.data
        dispatch(mfaRequiredToggleSuccess(data.resource_id, data))
        dispatch(addToast({
          message: 'MFA Required has been updated.',
          severity: 'success'
        }))
      } else {
        dispatch(mfaRequiredToggleFail('An error occurred while attempting to update mfa required.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to update mfa required.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(mfaRequiredToggleFail(error.response.data.errors))
    })
  }
}

export const fetchPaymentMethodUpdateUrlSuccess = (accountid) => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_SUCCESS,
    id: accountid
  }
}

export const fetchPaymentMethodUpdateUrlFail = (error) => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_FAIL,
    error: error
  }
}

export const fetchPaymentMethodUpdateUrlStart = () => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_UPDATE_URL_START
  }
}

export const fetchPaymentMethodUpdateUrl = (id, type) => {
  const account_data = {
    account_id: id,
    update_type: type
  }
  
  return dispatch => {
    dispatch(fetchPaymentMethodUpdateUrlStart())
    let url = '/stripe/get_billing_update_url'
    let config = authHeaders()
    axios.post(url, account_data, config)
    .then(response => {
      if (response && response.data && response.data.data && response.data.data.url){
        const fetchedUrl = {...response.data.data}
        dispatch(fetchPaymentMethodUpdateUrlSuccess(fetchedUrl.url))
        dispatch(openPaymentMethodUpdateDialog())
      } else {
        dispatch(fetchPaymentMethodUpdateUrlFail('An error occurred while attempting to retrieve payment method update URL.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to retrieve payment method update URL.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(fetchPaymentMethodUpdateUrlFail(error.response.data.errors))
    })
  }
}

export const openPaymentMethodUpdateDialog = () => {
  return {
    type: actionTypes.OPEN_PAYMENT_METHOD_UPDATE_DIALOG
  }
}

export const closePaymentMethodUpdateDialog = () => {
  return {
    type: actionTypes.CLOSE_PAYMENT_METHOD_UPDATE_DIALOG
  }
}

export const fetchPaymentMethodSuccess = (accountid) => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
    id: accountid
  }
}

export const fetchPaymentMethodFail = (error) => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_FAIL,
    error: error
  }
}

export const fetchPaymentMethodStart = () => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHOD_START
  }
}

export const fetchPaymentMethod = (id) => {
  const account_data = {
    account_id: id
  }
  
  return dispatch => {
    dispatch(fetchPaymentMethodStart())
    let url = '/stripe/get_payment_method'
    let config = authHeaders()
    axios.post(url, account_data, config)
    .then(response => {
      if (response && response.data && response.data.data){
        const fetchedData = {...response.data.data}
        if (fetchedData && fetchedData.card && fetchedData.card){
          dispatch(fetchPaymentMethodSuccess(fetchedData.card))
        } else {
          dispatch(fetchPaymentMethodFail('An error occurred while attempting to retrieve payment method.'))
          // dispatch(addToast({
          //   message: 'An error occurred while attempting to retrieve payment method.',
          //   severity: 'error',
          //   duration: null
          // }))
        }
      } else {
        dispatch(fetchPaymentMethodFail('An error occurred while attempting to retrieve payment method.'))
        // dispatch(addToast({
        //   message: 'An error occurred while attempting to retrieve payment method.',
        //   severity: 'error',
        //   duration: null
        // }))
      }
    })
    .catch(error => {
      dispatch(fetchPaymentMethodFail(error.response.data.errors))
    })
  }
}

export const exportContactsSuccess = (contacts, pagination) => {
  return {
    type: actionTypes.EXPORT_CONTACTS_SUCCESS,
    contacts: contacts
  }
}

export const exportContactsFail = (error) => {
  return {
    type: actionTypes.EXPORT_CONTACTS_FAIL,
    error: error
  }
}

export const exportContactsStart = () => {
  return {
    type: actionTypes.EXPORT_CONTACTS_START
  }
}

export const exportContacts = () => {
  return dispatch => {
    dispatch(exportContactsStart())
    let url = `/export_contacts`
    let config = authHeaders()
    axios.get(url, config)
    .then(response => {
      if (response){
        const exportedContacts = []
        for(let key in response.data.data){
          exportedContacts.push({
            ...response.data.data[key],
            id: key
          })
        }
        dispatch(exportContactsSuccess(exportedContacts))
      } else {
        dispatch(exportContactsFail('An error occurred while attempting to export contacts.'))
        dispatch(addToast({
          message: 'An error occurred while attempting to export contacts.',
          severity: 'error',
          duration: null
        }))
      }
    })
    .catch(error => {
      dispatch(exportContactsFail(error.response.data.errors))
    })
  }
}

export const resetContacts = () => {
  return {
    type: actionTypes.RESET_CONTACTS
  }
}