export const ADD_MERCHANT_START = 'ADD_MERCHANT_START'
export const ADD_MERCHANT_SUCCESS = 'ADD_MERCHANT_SUCCESS'
export const ADD_MERCHANT_FAIL = 'ADD_MERCHANT_FAIL'
export const UPDATE_MERCHANT_START = 'UPDATE_MERCHANT_START'
export const UPDATE_MERCHANT_SUCCESS = 'UPDATE_MERCHANT_SUCCESS'
export const UPDATE_MERCHANT_FAIL = 'UPDATE_MERCHANT_FAIL'
export const FETCH_MERCHANTS_START = 'FETCH_MERCHANTS_START'
export const FETCH_MERCHANTS_SUCCESS = 'FETCH_MERCHANTS_SUCCESS'
export const FETCH_MERCHANTS_FAIL = 'FETCH_MERCHANTS_FAIL'
export const FETCH_MERCHANT_START = 'FETCH_MERCHANT_START'
export const FETCH_MERCHANT_SUCCESS = 'FETCH_MERCHANT_SUCCESS'
export const FETCH_MERCHANT_FAIL = 'FETCH_MERCHANT_FAIL'
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT'
export const ARCHIVE_MERCHANT_START = 'ARCHIVE_MERCHANT_START'
export const ARCHIVE_MERCHANT_SUCCESS = 'ARCHIVE_MERCHANT_SUCCESS'
export const ARCHIVE_MERCHANT_FAIL = 'ARCHIVE_MERCHANT_FAIL'
export const SEARCH_MERCHANTS_START = 'SEARCH_MERCHANTS_START'
export const SEARCH_MERCHANTS_SUCCESS = 'SEARCH_MERCHANTS_SUCCESS'
export const SEARCH_MERCHANTS_FAIL = 'SEARCH_MERCHANTS_FAIL'
export const SEARCH_MERCHANTS_RESET = 'SEARCH_MERCHANTS_RESET'
export const SEARCH_MERCHANTS = 'SEARCH_MERCHANTS'
export const SEARCH_MERCHANTS_RESULTS = 'SEARCH_MERCHANTS_RESULTS'
export const OPEN_MERCHANT_NOT_FOUND_DIALOG = 'OPEN_MERCHANT_NOT_FOUND_DIALOG'
export const CLOSE_MERCHANT_NOT_FOUND_DIALOG = 'CLOSE_MERCHANT_NOT_FOUND_DIALOG'
export const OPEN_MERCHANT_EXISTS_DIALOG = 'OPEN_MERCHANT_EXISTS_DIALOG'
export const CLOSE_MERCHANT_EXISTS_DIALOG = 'CLOSE_MERCHANT_EXISTS_DIALOG'
export const INITIALIZE_ADD_MERCHANT = 'INITIALIZE_ADD_MERCHANT'
export const INITIALIZE_ADD_NOTE_TO_MERCHANT = 'INITIALIZE_ADD_NOTE_TO_MERCHANT'
export const OPEN_MERCHANT_DELETE_MODAL = 'OPEN_MERCHANT_DELETE_MODAL'
export const CLOSE_MERCHANT_DELETE_MODAL = 'CLOSE_MERCHANT_DELETE_MODAL'
export const TOGGLE_MERCHANT_SUBSCRIPTION_START = 'TOGGLE_MERCHANT_SUBSCRIPTION_START'
export const TOGGLE_MERCHANT_SUBSCRIPTION_SUCCESS = 'TOGGLE_MERCHANT_SUBSCRIPTION_SUCCESS'
export const TOGGLE_MERCHANT_SUBSCRIPTION_FAIL = 'TOGGLE_MERCHANT_SUBSCRIPTION_FAIL'

export const ADD_MERCHANT_SUBSCRIPTION_START = 'ADD_MERCHANT_SUBSCRIPTION_START'
export const ADD_MERCHANT_SUBSCRIPTION_SUCCESS = 'ADD_MERCHANT_SUBSCRIPTION_SUCCESS'
export const ADD_MERCHANT_SUBSCRIPTION_FAIL = 'ADD_MERCHANT_SUBSCRIPTION_FAIL'
export const UPDATE_MERCHANT_SUBSCRIPTION_START = 'UPDATE_MERCHANT_SUBSCRIPTION_START'
export const UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS = 'UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS'
export const UPDATE_MERCHANT_SUBSCRIPTION_FAIL = 'UPDATE_MERCHANT_SUBSCRIPTION_FAIL'
export const FETCH_MERCHANT_SUBSCRIPTIONS_START = 'FETCH_MERCHANT_SUBSCRIPTIONS_START'
export const FETCH_MERCHANT_SUBSCRIPTIONS_SUCCESS = 'FETCH_MERCHANT_SUBSCRIPTIONS_SUCCESS'
export const FETCH_MERCHANT_SUBSCRIPTIONS_FAIL = 'FETCH_MERCHANT_SUBSCRIPTIONS_FAIL'
export const FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_START = 'FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_START'
export const FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_SUCCESS = 'FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_SUCCESS'
export const FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_FAIL = 'FETCH_MERCHANT_SUBSCRIPTION_IMPORTS_FAIL'
export const FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_START = 'FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_START'
export const FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_SUCCESS = 'FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_SUCCESS'
export const FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_FAIL = 'FETCH_MERCHANT_SUBSCRIPTION_WEBHOOKS_FAIL'
export const FETCH_MERCHANT_SUBSCRIPTION_START = 'FETCH_MERCHANT_SUBSCRIPTION_START'
export const FETCH_MERCHANT_SUBSCRIPTION_SUCCESS = 'FETCH_MERCHANT_SUBSCRIPTION_SUCCESS'
export const FETCH_MERCHANT_SUBSCRIPTION_FAIL = 'FETCH_MERCHANT_SUBSCRIPTION_FAIL'
export const UPDATE_MERCHANT_SUBSCRIPTION = 'UPDATE_MERCHANT_SUBSCRIPTION'
export const ARCHIVE_MERCHANT_SUBSCRIPTION_START = 'ARCHIVE_MERCHANT_SUBSCRIPTION_START'
export const ARCHIVE_MERCHANT_SUBSCRIPTION_SUCCESS = 'ARCHIVE_MERCHANT_SUBSCRIPTION_SUCCESS'
export const ARCHIVE_MERCHANT_SUBSCRIPTION_FAIL = 'ARCHIVE_MERCHANT_SUBSCRIPTION_FAIL'
export const SEARCH_MERCHANT_SUBSCRIPTIONS_START = 'SEARCH_MERCHANT_SUBSCRIPTIONS_START'
export const SEARCH_MERCHANT_SUBSCRIPTIONS_SUCCESS = 'SEARCH_MERCHANT_SUBSCRIPTIONS_SUCCESS'
export const SEARCH_MERCHANT_SUBSCRIPTIONS_FAIL = 'SEARCH_MERCHANT_SUBSCRIPTIONS_FAIL'
export const SEARCH_MERCHANT_SUBSCRIPTIONS_RESET = 'SEARCH_MERCHANT_SUBSCRIPTIONS_RESET'
export const SEARCH_MERCHANT_SUBSCRIPTIONS = 'SEARCH_MERCHANT_SUBSCRIPTIONS'
export const SEARCH_MERCHANT_SUBSCRIPTIONS_RESULTS = 'SEARCH_MERCHANT_SUBSCRIPTIONS_RESULTS'
export const INITIALIZE_ADD_MERCHANT_SUBSCRIPTION = 'INITIALIZE_ADD_MERCHANT_SUBSCRIPTION'
export const INITIALIZE_ADD_NOTE_TO_MERCHANT_SUBSCRIPTION = 'INITIALIZE_ADD_NOTE_TO_MERCHANT_SUBSCRIPTION'
export const OPEN_MERCHANT_SUBSCRIPTION_DELETE_MODAL = 'OPEN_MERCHANT_SUBSCRIPTION_DELETE_MODAL'
export const CLOSE_MERCHANT_SUBSCRIPTION_DELETE_MODAL = 'CLOSE_MERCHANT_SUBSCRIPTION_DELETE_MODAL'
export const OPEN_MERCHANT_SUBSCRIPTION_DIALOG = 'OPEN_MERCHANT_SUBSCRIPTION_DIALOG'
export const CLOSE_MERCHANT_SUBSCRIPTION_DIALOG = 'CLOSE_MERCHANT_SUBSCRIPTION_DIALOG'
export const DELETE_MERCHANT_SUBSCRIPTIONS_START = 'DELETE_MERCHANT_SUBSCRIPTIONS_START'
export const DELETE_MERCHANT_SUBSCRIPTIONS_SUCCESS = 'DELETE_MERCHANT_SUBSCRIPTIONS_SUCCESS'
export const DELETE_MERCHANT_SUBSCRIPTIONS_FAIL = 'DELETE_MERCHANT_SUBSCRIPTIONS_FAIL'
export const DELETE_ALL_MERCHANT_SUBSCRIPTIONS_START = 'DELETE_ALL_MERCHANT_SUBSCRIPTIONS_START'
export const DELETE_ALL_MERCHANT_SUBSCRIPTIONS_SUCCESS = 'DELETE_ALL_MERCHANT_SUBSCRIPTIONS_SUCCESS'
export const DELETE_ALL_MERCHANT_SUBSCRIPTIONS_FAIL = 'DELETE_ALL_MERCHANT_SUBSCRIPTIONS_FAIL'

export const FETCH_SEARCH_COUNTS_SUCCESS = 'FETCH_SEARCH_COUNTS_SUCCESS'
export const FETCH_SEARCH_COUNTS_FAIL = 'FETCH_SEARCH_COUNTS_FAIL'
export const FETCH_SEARCH_COUNTS_START = 'FETCH_SEARCH_COUNTS_START'
export const RESET_SEARCH_COUNTS = 'RESET_SEARCH_COUNTS'

export const FETCH_DELETED_MERCHANTS_START = 'FETCH_DELETED_MERCHANTS_START'
export const FETCH_DELETED_MERCHANTS_SUCCESS = 'FETCH_DELETED_MERCHANTS_SUCCESS'
export const FETCH_DELETED_MERCHANTS_FAIL = 'FETCH_DELETED_MERCHANTS_FAIL'
export const FETCH_DELETED_MERCHANT_START = 'FETCH_DELETED_MERCHANT_START'
export const FETCH_DELETED_MERCHANT_SUCCESS = 'FETCH_DELETED_MERCHANT_SUCCESS'
export const FETCH_DELETED_MERCHANT_FAIL = 'FETCH_DELETED_MERCHANT_FAIL'
export const SEARCH_DELETED_MERCHANTS_START = 'SEARCH_DELETED_MERCHANTS_START'
export const SEARCH_DELETED_MERCHANTS_SUCCESS = 'SEARCH_DELETED_MERCHANTS_SUCCESS'
export const SEARCH_DELETED_MERCHANTS_FAIL = 'SEARCH_DELETED_MERCHANTS_FAIL'
export const SEARCH_DELETED_MERCHANTS_RESET = 'SEARCH_DELETED_MERCHANTS_RESET'
export const SEARCH_DELETED_MERCHANTS = 'SEARCH_DELETED_MERCHANTS'
export const SEARCH_DELETED_MERCHANTS_RESULTS = 'SEARCH_DELETED_MERCHANTS_RESULTS'

export const ADD_NOTE_START = 'ADD_NOTE_START'
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAIL = 'ADD_NOTE_FAIL'
export const UPDATE_NOTE = 'UPDATE_NOTE'
export const UPDATE_NOTE_START = 'UPDATE_NOTE_START'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL'
export const DISPUTE_NOTE = 'DISPUTE_NOTE'
export const DISPUTE_NOTE_START = 'DISPUTE_NOTE_START'
export const DISPUTE_NOTE_SUCCESS = 'DISPUTE_NOTE_SUCCESS'
export const DISPUTE_NOTE_FAIL = 'DISPUTE_NOTE_FAIL'
export const FETCH_NOTES_START = 'FETCH_NOTES_START'
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS'
export const FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL'
export const FETCH_NOTE_START = 'FETCH_NOTE_START'
export const FETCH_NOTE_SUCCESS = 'FETCH_NOTE_SUCCESS'
export const FETCH_NOTE_FAIL = 'FETCH_NOTE_FAIL'
export const ARCHIVE_NOTE_START = 'ARCHIVE_NOTE_START'
export const ARCHIVE_NOTE_SUCCESS = 'ARCHIVE_NOTE_SUCCESS'
export const ARCHIVE_NOTE_FAIL = 'ARCHIVE_NOTE_FAIL'
export const OPEN_NOTE_MODAL = 'OPEN_NOTE_MODAL'
export const CLOSE_NOTE_MODAL = 'CLOSE_NOTE_MODAL'
export const OPEN_NOTE_DISPUTE_MODAL = 'OPEN_NOTE_DISPUTE_MODAL'
export const CLOSE_NOTE_DISPUTE_MODAL = 'CLOSE_NOTE_DISPUTE_MODAL'

export const FETCH_DELETED_NOTES_START = 'FETCH_DELETED_NOTES_START'
export const FETCH_DELETED_NOTES_SUCCESS = 'FETCH_DELETED_NOTES_SUCCESS'
export const FETCH_DELETED_NOTES_FAIL = 'FETCH_DELETED_NOTES_FAIL'
export const FETCH_DELETED_NOTE_START = 'FETCH_DELETED_NOTE_START'
export const FETCH_DELETED_NOTE_SUCCESS = 'FETCH_DELETED_NOTE_SUCCESS'
export const FETCH_DELETED_NOTE_FAIL = 'FETCH_DELETED_NOTE_FAIL'

export const ADD_ACCOUNT_START = 'ADD_ACCOUNT_START'
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS'
export const ADD_ACCOUNT_FAIL = 'ADD_ACCOUNT_FAIL'
export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START'
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL'
export const FETCH_ACCOUNTS_START = 'FETCH_ACCOUNTS_START'
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNTS_FAIL = 'FETCH_ACCOUNTS_FAIL'
export const FETCH_ACCOUNT_START = 'FETCH_ACCOUNT_START'
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS'
export const FETCH_ACCOUNT_FAIL = 'FETCH_ACCOUNT_FAIL'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const GRANT_API_ACCESS_ACCOUNT_START = 'GRANT_API_ACCESS_ACCOUNT_START'
export const GRANT_API_ACCESS_ACCOUNT_SUCCESS = 'GRANT_API_ACCESS_ACCOUNT_SUCCESS'
export const GRANT_API_ACCESS_ACCOUNT_FAIL = 'GRANT_API_ACCESS_ACCOUNT_FAIL'
export const REVOKE_API_ACCESS_ACCOUNT_START = 'REVOKE_API_ACCESS_ACCOUNT_START'
export const REVOKE_API_ACCESS_ACCOUNT_SUCCESS = 'REVOKE_API_ACCESS_ACCOUNT_SUCCESS'
export const REVOKE_API_ACCESS_ACCOUNT_FAIL = 'REVOKE_API_ACCESS_ACCOUNT_FAIL'
export const ARCHIVE_ACCOUNT_START = 'ARCHIVE_ACCOUNT_START'
export const ARCHIVE_ACCOUNT_SUCCESS = 'ARCHIVE_ACCOUNT_SUCCESS'
export const ARCHIVE_ACCOUNT_FAIL = 'ARCHIVE_ACCOUNT_FAIL'
export const FETCH_DATAMERCH_MEMBERS_START = 'FETCH_DATAMERCH_MEMBERS_START'
export const FETCH_DATAMERCH_MEMBERS_SUCCESS = 'FETCH_DATAMERCH_MEMBERS_SUCCESS'
export const FETCH_DATAMERCH_MEMBERS_FAIL = 'FETCH_DATAMERCH_MEMBERS_FAIL'
export const START_TRIAL_START = 'START_TRIAL_START'
export const START_TRIAL_SUCCESS = 'START_TRIAL_SUCCESS'
export const START_TRIAL_FAIL = 'START_TRIAL_FAIL'
export const CANCEL_TRIAL_START = 'CANCEL_TRIAL_START'
export const CANCEL_TRIAL_SUCCESS = 'CANCEL_TRIAL_SUCCESS'
export const CANCEL_TRIAL_FAIL = 'CANCEL_TRIAL_FAIL'
export const MEMBERSHIP_UPGRADE_START = 'MEMBERSHIP_UPGRADE_START'
export const MEMBERSHIP_UPGRADE_SUCCESS = 'MEMBERSHIP_UPGRADE_SUCCESS'
export const MEMBERSHIP_UPGRADE_FAIL = 'MEMBERSHIP_UPGRADE_FAIL'
export const MEMBERSHIP_DOWNGRADE_START = 'MEMBERSHIP_DOWNGRADE_START'
export const MEMBERSHIP_DOWNGRADE_SUCCESS = 'MEMBERSHIP_DOWNGRADE_SUCCESS'
export const MEMBERSHIP_DOWNGRADE_FAIL = 'MEMBERSHIP_DOWNGRADE_FAIL'
export const CONTRIBUTION_OVERRIDE_TOGGLE_START = 'CONTRIBUTION_OVERRIDE_TOGGLE_START'
export const CONTRIBUTION_OVERRIDE_TOGGLE_SUCCESS = 'CONTRIBUTION_OVERRIDE_TOGGLE_SUCCESS'
export const CONTRIBUTION_OVERRIDE_TOGGLE_FAIL = 'CONTRIBUTION_OVERRIDE_TOGGLE_FAIL'
export const CONTRIBUTION_RECALCULATE_START = 'CONTRIBUTION_RECALCULATE_START'
export const CONTRIBUTION_RECALCULATE_SUCCESS = 'CONTRIBUTION_RECALCULATE_SUCCESS'
export const CONTRIBUTION_RECALCULATE_FAIL = 'CONTRIBUTION_RECALCULATE_FAIL'
export const MFA_REQUIRED_TOGGLE_START = 'MFA_REQUIRED_TOGGLE_START'
export const MFA_REQUIRED_TOGGLE_SUCCESS = 'MFA_REQUIRED_TOGGLE_SUCCESS'
export const MFA_REQUIRED_TOGGLE_FAIL = 'MFA_REQUIRED_TOGGLE_FAIL'
export const FETCH_PAYMENT_METHOD_UPDATE_URL_START = 'FETCH_PAYMENT_METHOD_UPDATE_URL_START'
export const FETCH_PAYMENT_METHOD_UPDATE_URL_SUCCESS = 'FETCH_PAYMENT_METHOD_UPDATE_URL_SUCCESS'
export const FETCH_PAYMENT_METHOD_UPDATE_URL_FAIL = 'FETCH_PAYMENT_METHOD_UPDATE_URL_FAIL'
export const OPEN_PAYMENT_METHOD_UPDATE_DIALOG = 'OPEN_PAYMENT_METHOD_UPDATE_DIALOG'
export const CLOSE_PAYMENT_METHOD_UPDATE_DIALOG = 'CLOSE_PAYMENT_METHOD_UPDATE_DIALOG'
export const FETCH_PAYMENT_METHOD_START = 'FETCH_PAYMENT_METHOD_START'
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS'
export const FETCH_PAYMENT_METHOD_FAIL = 'FETCH_PAYMENT_METHOD_FAIL'
export const EXPORT_CONTACTS_START = 'EXPORT_CONTACTS_START'
export const EXPORT_CONTACTS_SUCCESS = 'EXPORT_CONTACTS_SUCCESS'
export const EXPORT_CONTACTS_FAIL = 'EXPORT_CONTACTS_FAIL'
export const RESET_CONTACTS = 'RESET_CONTACTS'


export const ADD_USER_START = 'ADD_USER_START'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAIL = 'ADD_USER_FAIL'
export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'
export const FETCH_USER_START = 'FETCH_USER_START'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL'
export const UPDATE_USER = 'UPDATE_USER'
export const RESTORE_USER_START = 'RESTORE_USER_START'
export const RESTORE_USER_SUCCESS = 'RESTORE_USER_SUCCESS'
export const RESTORE_USER_FAIL = 'RESTORE_USER_FAIL'
export const ARCHIVE_USER_START = 'ARCHIVE_USER_START'
export const ARCHIVE_USER_SUCCESS = 'ARCHIVE_USER_SUCCESS'
export const ARCHIVE_USER_FAIL = 'ARCHIVE_USER_FAIL'
export const TOGGLE_ADMIN_USER_START = 'TOGGLE_ADMIN_USER_START'
export const TOGGLE_ADMIN_USER_SUCCESS = 'TOGGLE_ADMIN_USER_SUCCESS'
export const TOGGLE_ADMIN_USER_FAIL = 'TOGGLE_ADMIN_USER_FAIL'
export const GRANT_API_ACCESS_USER_START = 'GRANT_API_ACCESS_USER_START'
export const GRANT_API_ACCESS_USER_SUCCESS = 'GRANT_API_ACCESS_USER_SUCCESS'
export const GRANT_API_ACCESS_USER_FAIL = 'GRANT_API_ACCESS_USER_FAIL'
export const REVOKE_API_ACCESS_USER_START = 'REVOKE_API_ACCESS_USER_START'
export const REVOKE_API_ACCESS_USER_SUCCESS = 'REVOKE_API_ACCESS_USER_SUCCESS'
export const REVOKE_API_ACCESS_USER_FAIL = 'REVOKE_API_ACCESS_USER_FAIL'
export const OPEN_API_CREDENTIAL_MODAL = 'OPEN_API_CREDENTIAL_MODAL'
export const CLOSE_API_CREDENTIAL_MODAL = 'CLOSE_API_CREDENTIAL_MODAL'
export const OPEN_PASSWORD_MODAL = 'OPEN_PASSWORD_MODAL'
export const CLOSE_PASSWORD_MODAL = 'CLOSE_PASSWORD_MODAL'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'
export const FORGET_DEVICE = 'FORGET_DEVICE'
export const FORGET_DEVICE_START = 'FORGET_DEVICE_START'
export const FORGET_DEVICE_SUCCESS = 'FORGET_DEVICE_SUCCESS'
export const FORGET_DEVICE_FAIL = 'FORGET_DEVICE_FAIL'
export const RESET_MFA = 'RESET_MFA'
export const RESET_MFA_START = 'RESET_MFA_START'
export const RESET_MFA_SUCCESS = 'RESET_MFA_SUCCESS'
export const RESET_MFA_FAIL = 'RESET_MFA_FAIL'
export const MFA_REQUIRED_TOGGLE_USER_START = 'MFA_REQUIRED_TOGGLE_USER_START'
export const MFA_REQUIRED_TOGGLE_USER_SUCCESS = 'MFA_REQUIRED_TOGGLE_USER_SUCCESS'
export const MFA_REQUIRED_TOGGLE_USER_FAIL = 'MFA_REQUIRED_TOGGLE_USER_FAIL'

export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const AUTH_AUTO_LOGIN = 'AUTH_AUTO_LOGIN'
export const AUTH_UPDATE_ACCOUNT = 'AUTH_UPDATE_ACCOUNT'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_START = 'PASSWORD_RESET_START'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'
export const AGREE_TO_TERMS = 'AGREE_TO_TERMS'
export const AGREE_TO_TERMS_START = 'AGREE_TO_TERMS_START'
export const AGREE_TO_TERMS_SUCCESS = 'AGREE_TO_TERMS_SUCCESS'
export const AGREE_TO_TERMS_FAIL = 'AGREE_TO_TERMS_FAIL'
export const AGREED_TO_TERMS = 'AGREED_TO_TERMS'
export const FETCH_DASHBOARDUPDATES_START = 'FETCH_DASHBOARDUPDATES_START'
export const FETCH_DASHBOARDUPDATES_SUCCESS = 'FETCH_DASHBOARDUPDATES_SUCCESS'
export const FETCH_DASHBOARDUPDATES_FAIL = 'FETCH_DASHBOARDUPDATES_FAIL'
export const AUTH_RESET_MFA_SUCCESS = 'AUTH_RESET_MFA_SUCCESS'


export const SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE'
export const SUBSCRIPTION_CREATE_START = 'SUBSCRIPTION_CREATE_START'
export const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS'
export const SUBSCRIPTION_CREATE_FAIL = 'SUBSCRIPTION_CREATE_FAIL'
export const SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED'
export const SUBSCRIPTION_CANCEL = 'SUBSCRIPTION_CANCEL'
export const SUBSCRIPTION_CANCEL_START = 'SUBSCRIPTION_CANCEL_START'
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS'
export const SUBSCRIPTION_CANCEL_FAIL = 'SUBSCRIPTION_CANCEL_FAIL'
export const SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED'

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

export const UPDATE_APPSETTINGS_START = 'UPDATE_APPSETTINGS_START'
export const UPDATE_APPSETTINGS_SUCCESS = 'UPDATE_APPSETTINGS_SUCCESS'
export const UPDATE_APPSETTINGS_FAIL = 'UPDATE_APPSETTINGS_FAIL'
export const FETCH_APPSETTINGS_START = 'FETCH_APPSETTINGS_START'
export const FETCH_APPSETTINGS_SUCCESS = 'FETCH_APPSETTINGS_SUCCESS'
export const FETCH_APPSETTINGS_FAIL = 'FETCH_APPSETTINGS_FAIL'

export const FETCH_INVOICES_START = 'FETCH_INVOICES_START'
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS'
export const FETCH_INVOICES_FAIL = 'FETCH_INVOICES_FAIL'

export const ADD_DISPUTE_START = 'ADD_DISPUTE_START'
export const ADD_DISPUTE_SUCCESS = 'ADD_DISPUTE_SUCCESS'
export const ADD_DISPUTE_FAIL = 'ADD_DISPUTE_FAIL'
export const INITIALIZE_ADD_DISPUTE = 'INITIALIZE_ADD_DISPUTE'

export const NEW_OTP_START = 'NEW_OTP_START'
export const NEW_OTP_SUCCESS = 'NEW_OTP_SUCCESS'
export const NEW_OTP_FAIL = 'NEW_OTP_FAIL'
export const VERIFY_OTP_START = 'VERIFY_OTP_START'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'
export const VERIFY_OTP_RESULT = 'VERIFY_OTP_RESULT'
export const FETCH_OTPURL_START = 'FETCH_OTPURL_START'
export const FETCH_OTPURL_SUCCESS = 'FETCH_OTPURL_SUCCESS'
export const FETCH_OTPURL_FAIL = 'FETCH_OTPURL_FAIL'